<template>
    <div class="backdrop">
        <div class="container h-100">
            <div class="row h-100 justify-content-center align-items-center">

                <div class="card shadow login" style="width: 475px;">

                    <div class="card-header light" style="text-align: center;">
                        {{ toolName }}
                    </div>

                    <div class="card-body m-1">
                        <form v-on:keyup.enter="login">
                            <div class="form-group">
                                <input id="email" ref="email" type="email" class="form-control" placeholder="Email" v-model="email">
                            </div>
                            <div class="form-group">
                                <input id="password" :type="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password" v-model="password">
                            </div>
                            <div class="form-check">
                                <input id="showpassword" type="checkbox" class="form-check-input" v-model="showPassword">
                                <label class="form-check-label" for="showpassword">Show Password</label>
                            </div>
                        </form>
                        <div v-if="loginError" class="mt-2" style="color: red; text-align: center;">{{ loginError }}</div>
                    </div>

                    <div class="card-footer light" style="text-align: center;">
                        <button type="button" class="btn btn-success rounded-pill pl-5 pr-5" :disabled="!email || !password" v-on:click="login">
                            Login
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginDialog",
    data() {
        return {
            email: '',
            password: '',
            showPassword: false,
            loginError: null,
            toolName: process.env.VUE_APP_NAME,
        }
    },
    mounted() {
        this.$refs.email.focus();
    },
    methods: {
        login() {
            this.loginError = null;
            this.$http.post('/auth/login', {
                email: this.email,
                password: this.password,
            }, {}).then((response) => {
                this.$emit('login-successfull', response.data);
            }).catch(() => {
                this.loginError = 'Your email or password is not correct';
            });
        },
    }
}
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99900;
    backdrop-filter: blur(10px);
    background: rgba(25, 25, 25, 0.6);
    overflow: auto;
}
</style>
